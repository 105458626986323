import React from 'react';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as firebase from "firebase/app";
import "firebase/firestore";

class RSVPItem extends React.Component{
	render(){
		return (
			<Row>
				<Col>
					{this.props.name}
				</Col>
				<Col>
					{this.props.email}
				</Col>
			</Row>
		);
	}
}

class RSVPInput extends React.Component{
	state ={
		name: null,
		email: ''
	}
	render(){
		return (
			<Row>
				<Col>
		      <InputGroup className="mb-3">
		        <FormControl
		          placeholder="Name"
		          aria-label="Name of attendee"
		          aria-describedby="basic-addon2"
		          onChange={(e)=>{
		            this.setState({
		            	name: e.target.value
		            })
		          }}
		        />
		        <FormControl 
		          placeholder="Email (Optional)"
		          aria-label="Email of attendee (optional)"
		          aria-describedby="basic-addon2"
		          onChange={(e)=>{
		            this.setState({
		            	email: e.target.value
		            })
		          }}
		        />
            <InputGroup.Append>
              <Button variant="outline-secondary" onClick={()=>{
              	let {name,email} = this.state;
              	if(!name || name.length < 1){
              		alert('Please enter a name for the attendee');
              		return;
              	}

              	this.props.add({
              		name,
              		email,
              		added: true
              	});
              }}>Add</Button>
            </InputGroup.Append>
		      </InputGroup>
	      </Col>
      </Row>
    );
	}
}

class RSVP extends React.Component{
	state = {
		rsvps: [
			{added: null}
		],
		done: false
	}

	addPerson(p){
		this.setState((prevState)=>{
			let {rsvps} = prevState;
			rsvps.unshift(p);
			return {
				rsvps
			}
		})
	}

	saveRSVP(){
		let {eventId} = this.props;
		let {rsvps} = this.state;
		console.log("Hit save, our current state", this.state);
		console.log("event props", this.props);
		let people = rsvps.filter(p => p.added).map((n)=>{ 
			return {name: n.name, email: n.email};
		});
		console.log("adding these people", people);
		if(people.length<1){
			alert("Please enter at least one person to RSVP");
			return;
		}
		// Add a new document with a generated id.
		var rsvpsRef = firebase.firestore().collection("rsvps").doc();

		// later...
		rsvpsRef.set({event: eventId,people: people})
			.then(()=>{
				this.setState({done:true});
			})
	}

	render(){
		console.log('current state:',this.state);
		let {rsvps, done} = this.state;
		let rsvpNum = rsvps.filter((r)=> r.added).length;
		let buttonLang = 'Click to RSVP';
		if(rsvpNum >0 ){
			buttonLang += ' for ' + rsvpNum + ' ' + ( (rsvpNum > 1) ? 'persons' : 'person' );
		} 
		let buttonDisabled = false;
		if (rsvpNum < 1){
			buttonDisabled = true;
		}

		return (
			<Container>
				{ !done && (
				<Container>
					{ this.state.rsvps.map((r,ind)=>{
						if(r.added){
							return <RSVPItem key={ind} {...r} />
						}else{
							return <RSVPInput key={ind} add={this.addPerson.bind(this)} />
						}
					})}
					<Row>
						<Col>
							<Button onClick={this.saveRSVP.bind(this)} disabled={buttonDisabled}>{buttonLang}</Button>
						</Col>
					</Row>
				</Container>
				) }

				{done && (
					<Row>
						<Col>
							<h5 style={{color: '#09e9f4'}}>Thank you!</h5>
						</Col>
					</Row>
				)}
			</Container>
		);	
	}
}

export default RSVP;