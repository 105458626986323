import React from 'react';
import './App.css';
import './firebase';

import MainContainer from './MainContainer';

function App() {
  return (
    <div className="App">
      <div className="App-body">
        <MainContainer />
      </div>
    </div>
  );
}

export default App;
