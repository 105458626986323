import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RSVP from './RSVP';

import moment from 'moment';

class EventView extends React.Component{
	render(){
		let {
			title, 
			description, 
			event_date, 
			info, 
			details,
			location,
			id,
			address
		} = this.props.event;

		let niceDate = moment(event_date).format('LL');
		console.log('event view props', this.props);
		return(
		<Container>
			<Row>
				<Col>
					<h2 style={{color: '#09e9f4'}}>{title}</h2>
					<h4>{niceDate}</h4>
					<div style={{border: "2px solid #ffffff", 'borderRadius': '4px'}}>
						<span>{address}</span><br/>
						<span>{location}</span>
					</div>
					<p>{description}</p>
					<p>{details}</p>
					<p>{info}</p>
				</Col>
			</Row>
			<Row>
				<RSVP eventId={id} />
			</Row>
		</Container>
		)
	}
}

export default EventView