import * as firebase from 'firebase/app'
const config = {
  apiKey: "AIzaSyCPLkMrMIbQ1wjN-aupGrxacniNZXjky9s",
  authDomain: "extravaganser-c0877.firebaseapp.com",
  databaseURL: "https://extravaganser-c0877.firebaseio.com",
  projectId: "extravaganser-c0877",
  storageBucket: "extravaganser-c0877.appspot.com",
  messagingSenderId: "144907556958",
  appId: "1:144907556958:web:76913398e8c7da90"
};
firebase.initializeApp(config);
export default firebase;