import React from 'react';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';

import * as firebase from "firebase/app";
import "firebase/firestore";

const eventsRef = firebase.firestore().collection("events");

class EventDateForm extends React.Component{
  state ={
    year: '',
    month: '',
    day: '',
    error: null,
    showToast: false,
  }
  _checkDate(){
    console.log("date pressed");
    let {year, month, day} = this.state;
    if(!year){
      this.setState({error: 'Invalid year entered', showToast: true});
      return;
    }
    if(!month){
      this.setState({error: 'Invalid month entered', showToast: true});
      return;
    }
    if(!day){
      this.setState({error: 'Invalid day entered', showToast: true});
      return;
    }

    this._pullEvent(year + '-' + month + '-' + day);
  }

  _pullEvent(date){
    // Create a reference to the cities collection


    // Create a query against the collection.
    eventsRef.where("event_date", "==", date)
      .get()
      .then((eventSnaps)=>{
        if(eventSnaps.empty){
          this.setState({error: 'No events found for that date',showToast:true})
        }
        console.log('back with eventSnaps');
          eventSnaps.forEach((event) => {
            console.log( event.data() );
            let eData = event.data();
            eData.id = event.id;
            this.props.setCurrentEvent(eData);
          });
      })
      .catch((e)=>{
        console.warn(e);
      })
  }

  render(){
    console.log("new state", this.state);
    let {showToast, error} = this.state;

    return(
      <Container>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="YYYY"
                aria-label="Event year in YYYY format"
                aria-describedby="basic-addon2"
                value={this.state.year}
                onChange={(e)=>{
                  let y = (e.target.value ? (e.target.value).trim() : '');
                  this.setState({year:y})
                }}
              />
              <FormControl 
                placeholder="MM"
                aria-label="Event month in MM format"
                aria-describedby="basic-addon2"
                onChange={(e)=>{
                  let m = (e.target.value ? (e.target.value).trim() : '');
                  this.setState({month: m })
                }}
                value={this.state.month}
                onBlur={(e)=>{
                  console.log('month blurred');
                  this.setState((prevState)=>{
                    console.log('prev month state after blur', prevState.month);
                    if(prevState.month && (prevState.month.length < 2)){
                      prevState.month = '0'+prevState.month;
                      return prevState;
                    }else{
                      return null;
                    }
                  })
                }}
              />
              <FormControl 
                placeholder="DD"
                aria-label="Event day in DD format"
                aria-describedby="basic-addon2"
                onChange={(e)=>{
                  let d = (e.target.value ? (e.target.value).trim() : '');
                  this.setState({day: d})
                }}
                value={this.state.day}
                onBlur={(e)=>{
                  console.log('day blurred');
                  this.setState((prevState)=>{
                    console.log('prev day state after blur', prevState.day);
                    if(prevState.day && prevState.day.length < 2 ){
                      prevState.day = '0' + prevState.day;
                      return prevState;
                    }else{
                      return null;
                    }
                  })
                }}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={this._checkDate.bind(this)}>GO</Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        <Row>
        <Col>
          <p>
            Welcome, enter the date of the event to get started.
          </p>
        </Col>
        </Row>


        <Toast show={showToast} onClose={()=>{
          this.setState({showToast:false})
        }} style={{position: 'absolute',top:40}}>
          <Toast.Header>
            <strong variant='danger'>Error</strong>
          </Toast.Header>
          <Toast.Body>
            <p style={{color: '#000000'}}>{error} </p>
          </Toast.Body>
        </Toast>

      </Container>


    )
  }
}

export default EventDateForm;