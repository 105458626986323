import React from 'react';
import EventDateForm from './EventDateForm';
import EventView from './EventView';

class MainContainer extends React.Component{
	state = {
		event: null
	}

	_eventSet(e){
		this.setState({event: e});
	}

	render(){
		let {event} = this.state;
		console.log('using event', event);
		return (
			<div>
				{ !event && (
					<h1 style={{color: '#09e9f4'}}>EXTRAVAGANSER</h1>
				)}

				{ event && (
					<EventView event={event} />
				) }

				{ !event && (
					<EventDateForm setCurrentEvent={this._eventSet.bind(this)} />
				) }
			</div>
		);

	}
}

export default MainContainer;